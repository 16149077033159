
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '@/mixins/StepMixin'
import Joi from 'joi'
import diyConfig from '@/config/config'
import HTTPClient from '@/utils/HTTPClient'

@Component
export default class Account extends Mixins(Step) {
  form: any = {
    email: '',
    password: '',
    optin: false,
    terms: false,
    username: ''
  }

  schemas = {
    email: Joi.string().email({
      minDomainSegments: 2,
      tlds: false
    }).external(async (value) => {
      const isEmailBlackListed = await this.checkEmailIsBlacklisted(value)
      if (isEmailBlackListed) {
        throw new Joi.ValidationError(
          'email.blacklisted',
          [
            {
              message: 'email.blacklisted',
              path: ['email'],
              type: 'email.blacklisted',
              context: {
                key: 'email',
                label: 'email',
                value
              }
            }
          ],
          value
        )
      }
      const check = await this.checkEmailExists(value)
      if (!check) {
        throw new Joi.ValidationError(
          'email.unique',
          [
            {
              message: 'email.unique',
              path: ['email'],
              type: 'email.unique',
              context: {
                key: 'email',
                label: 'email',
                value
              }
            }
          ],
          value
        )
      }

      return undefined
    }),
    username: Joi.string().regex(/^[a-zA-Z]/).regex(/^.*(?=.{5,25})(?=.*[a-zA-Z])(?!.*[!@#£$§%€&/([\]=?«»',;.:_~^ºª+*¨´`<>| )]).*$/).required().external(async (value) => {
      const check = await this.checkUsernameExists(value)
      if (!check) {
        throw new Joi.ValidationError(
          'username.unique',
          [
            {
              message: 'username.unique',
              path: ['username'],
              type: 'username.unique',
              context: {
                key: 'username',
                label: 'username',
                value
              }
            }
          ],
          value
        )
      } else {
        return undefined
      }
    }),
    password: Joi.string().regex(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!@#£$§%€&/([\]=?«»',;.:_~^ºª+*¨´`<>|)]).*$/).required(),
    optin: Joi.boolean(),
    terms: Joi.boolean().valid(true)
  }

  errors: any = {
    email: null,
    password: null,
    terms: null,
    username: null
  }

  privacyLink = diyConfig.PRIVACY_LINK
  conditionsLink = diyConfig.CONDITIONS_LINK
  isBusy = false

  mounted (): void {
    this.$store.dispatch('setCurrentPageInvalid')
    this.$store.dispatch('setNextStatus', true)

    const storedData = this.$store.state.userData.account
    for (const prop in storedData) {
      this.form[prop] = storedData[prop]
    }

    this.validateFields()
  }

  get showFormErrors () {
    return this.$store.getters.formErrors
  }

  initFlow (): void {
    if (!this.$store.getters.flowStarted) {
      this.$store.dispatch('setFlowStarted', true)
      this.preventLeavePage()
    }
  }

  async validateFields (): Promise<any> {
    this.isBusy = true
    const result = await this.validate(this.schemas, this.form)
    if (result?.errors !== undefined) {
      this.errors = {}
      Object.assign(this.errors, result.errors)
      this.errors.terms = this.errors.terms ? this.$t('steps.account.errors.terms') : ''
      this.$store.dispatch('setCurrentPageInvalid')
    } else {
      this.errors = {}
      this.$store.dispatch('setUserData', {
        account: this.form
      })
      this.$store.dispatch('setCurrentPageValid')
    }
    this.isBusy = false
  }

  async checkEmailExists (value: any): Promise<any> {
    if (this.form.email || this.form.username) {
      const apiurl = 'https://mono.site/api.php/diy'
      const apiRequest = new HTTPClient(apiurl)

      return await apiRequest.post('/site/validate-account', {
        email: value
      }).then((response) => {
        if (response.data && response.data.message === 'OK') {
          return true
        }
      }).catch((error) => {
        if (error.response.data.message === 'INVALID_EMAIL') {
          return false
        }
        return true
      })
    }
  }

  async checkEmailIsBlacklisted (email: string) {
    // get list
    const apiUrl = window.location.hostname === 'localhost'
      ? 'http://127.0.0.1:3000/dev'
      // ? 'http://u689.site.docker/api.php/diy'
      : 'https://mono.site/api.php/diy'

    const apiRequest = new HTTPClient(apiUrl)

    return await apiRequest.post('/site/validate-email', {
      data: {
        email: email
      }
    }).then((response) => {
      return response.data.data
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,handle-callback-err
    }).catch((error) => {
      if (error.response.status === 409 && error.response.data.data.error === 'EMAIL_INVALID') {
        return true
      }
      return false
    })
  }

  async checkUsernameExists (value: any): Promise<any> {
    if (this.form.email || this.form.username) {
      const apiurl = 'https://mono.site/api.php/diy'
      const apiRequest = new HTTPClient(apiurl)

      return await apiRequest.post('/site/validate-account', {
        username: value
      }).then((response) => {
        if (response.data && response.data.message === 'OK') {
          return true
        }
      }).catch((error) => {
        if (error.response.data.message === 'INVALID_USERNAME') {
          return false
        }
        return true
      })
    }
  }
}
